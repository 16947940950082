<template lang="pug">
#charterWindowDestination.charter-search__window(
  ref="charterWindowDestination",
  key="charterWindowDestination"
)
  .charter-search__window-content.overflow-auto
    PackageSearchBarWindowHeader.mx-n3.mx-sm-n3.px-3.px-md-0.mx-md-0(
      show-next
      :show-close="false"
      :select-error="selectDestinationFirst ? $t('charterSelectDestinationFirst') : null"
      @next="$emit('search')"
    ) {{ $t('charterSelectDestinations') }}

    ScreenWidthProvider.charter-search__list.checkboxes.mt-2(v-slot="{ isNarrower }")
      .d-flex.gap-3.flex-wrap.mb-lg-3.pb-3.px-md-0.mx-n1.mx-sm-0(v-if="categoriesForAirport.length")
        .d-flex.d-lg-contents.justify-content-between.w-100.gap-3(
          v-for="(categoryChunk, idx) in chunkedCategoriesForAirport"
          :key="idx",
        )
          ClickableChip.mb-auto.text-left.charter-destination-select__category-chip(
            v-for="category in categoryChunk"
            :key="category.id"
            :active="stagedCategoriesIds.includes(category.id)"
            :icon="stagedCategoriesIds.includes(category.id) ? icons.faCheck : undefined"
            inactive-class="bg-light-turquoise"
            active-class="bg-vivid-turquoise"
            @click="toggleCategory(category)"
          ) {{ category.name }}
        .d-inline-flex.flex-column.ml-auto
          button.shadow-none.btn-sm.btn-light.rounded-3.px-3.font-weight-medium(
            data-test="clear-destination-options"
            @click="handleCategories([])"
          )
            fa.d-none.d-lg-inline.mr-2(icon="xmark")
            | {{ $t('charterUnselectDestinations') }}
          button.shadow-none.btn-sm.btn-light.rounded-3.text-capitalize.mt-2.px-3.font-weight-medium(
            v-if="isNarrower('lg')"
            @click="$emit('clear')"
          ) {{ $t('close') }}

      .d-flex
        RelativeDropdown.px-3.mx-n3.mx-lg-n2.px-lg-2.flex-fill.d-flex.flex-column(
          :initial-expanded="isNarrower('lg') || !categoriesForAirport.length"
          menu-class="mx-n2 px-lg-2"
          width="unset"
          dropdown-class="d-flex"
        )
          template(#trigger="{ toggle, isOpen }")
            button.shadow-none.text-black.text-transform-none.mx-n3.mx-lg-0.text-xl.py-2.px-3.px-lg-4.text-left.d-flex.align-items-center.justify-content-between.flex-fill.bg-light-turquoise.font-weight-medium(
              type="button"
              :class="{ 'pe-none': isNarrower('lg') || !categoriesForAirport.length }"
              data-test-destination-dropdowns-toggle
              @click="toggle"
            )
              .pl-1(v-if="isNarrower('lg') || !categoriesForAirport.length") {{ $t('packagessearchbar.seeAllTripsMobile') }}
              template(v-else)
                | {{ $t('packagessearchbar.seeAllTrips') }}
                fa(
                  v-if="categoriesForAirport.length"
                  :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                )

          template(#content)
            ul.five-columns.charter-search__list.px-2.px-md-0.pt-2.mt-lg-1.pt-lg-3
              li.px-2.mx-n2.mb-2.charter-destination-select__country-group.overflow-hidden(
                v-for="(destinationL1, index) in preparedDestinations",
                :key="`d${index}`"
                :class="{ 'active-country': stagedDestinationsL1ForAirport.includes(destinationL1.name) }"
                data-test-country-group
              )
                button.font-weight-bold.bg-transparent(
                  data-test-country-button
                  @click="handleDestinationL1(destinationL1.name)"
                ) {{ destinationL1.name }}

                ul.mb-0
                  li.px-2.mx-n2(
                    v-for="destination in destinationL1.destinations",
                    :key="destination.destination_id"
                    :class="{ 'active-destination': stagedDestinationsIds.includes(destination.destination_id) && !stagedDestinationsL1ForAirport.includes(destinationL1.name) }"
                  )
                    button.bg-transparent(
                      :class="{ 'hover-font-weight-bold': !stagedDestinationsIds.includes(destination.destination_id) }"
                      data-test-destination-button
                      @click="handleDestination(destination)"
                    ) {{ destination.name }}
</template>

<script>
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { mapState, mapActions } from 'pinia'

export default defineNuxtComponent({
  name: 'DestinationSelect',

  props: {
    selectDestinationFirst: {
      type: Boolean,
      required: true
    },
  },

  emits: ['search', 'clear'],

  data () {
    return {
      icons: {
        faCheck,
        faTimes
      }
    }
  },

  computed: {
    ...mapState(useCharterPackagesStore, [
      'categories',
      'destinations',
      'groupedDestinationsForAirport',
      'categoriesForAirport'
    ]),

    ...mapState(useSearchWidgetsStore, {
      stagedDestinations: state => state.destinations.stagedDestinations,
      stagedCategories: state => state.destinations.stagedCategories,
      stagedDestinationsL1ForAirport: 'stagedDestinationsL1ForAirport',
      stagedCategoriesIds: 'stagedCategoriesIds'
    }),

    chunkedCategoriesForAirport () {
      return chunkArray(this.categoriesForAirport, 2)
    },

    stagedDestinationsIds () {
      return this.stagedDestinations.map(d => d?.destination_id).filter(d => !!d)
    },

    preparedDestinations () {
      return this.groupedDestinationsForAirport
        .map(x => ({
          ...x,
          destinations: x.destinations?.filter(({ name }) => name !== x.name)
        }))
    },
  },

  async fetch () {
    // NOTE this should be fetched at page level, added this here just in case it's not
    await this.fetchCharterDestinations()
  },

  methods: {
    ...mapActions(useSearchWidgetsStore, [
      'handleDestination',
      'handleDestinationL1',
      'toggleCategory',
      'handleCategories',
    ]),

    ...mapActions(useRootStore, ['fetchCharterDestinations'])
  }
})
</script>

<style lang="scss" scoped>
$active-color: $vivid-turquoise;
$active-border-radius: 10px;
$active-animation-duration: 150ms;

.charter-destination-select__category-chip {
  @media (max-width: $sm) {
    font-size: 0.8rem;
  }

  @media (max-width: 400px) {
    font-size: 0.74rem;
  }
}

.charter-destination-select {
  &__country-group {
    border-radius: $active-border-radius;
    transition: background $active-animation-duration ease-in-out;

    li {
      transition-property: background, border-radius;
      transition-duration: $active-animation-duration;
      transition-timing-function: ease-in-out;
    }

    &.active-country {
      background: $active-color !important;
    }

    .active-destination {
      background: $active-color !important;
      border-top-left-radius: $active-border-radius;
      border-top-right-radius: $active-border-radius;

      &:last-of-type,
      &:has(+ :not(.active-destination)) {
        border-bottom-left-radius: $active-border-radius;
        border-bottom-right-radius: $active-border-radius;
      }

      & + .active-destination {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }
}
</style>
